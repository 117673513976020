var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"page-message-order-list"},[_c('Search',{on:{"on-search":_vm.handle_search}}),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
          _vm.pagination.pageSize = size;
          _vm.async_get_list();
        },"page-change":(page) => {
          _vm.pagination.page = page;
          _vm.async_get_list();
        }},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"},[(row.processingStatus === 0)?_c('span',{staticClass:"iconfont icon-chulixuqiu1",attrs:{"title":"处理需求"},on:{"click":function($event){return _vm.$router.push(`/platform/order/handle/${row.id}`)}}}):_vm._e(),_c('span',{staticClass:"iconfont icon-chakangengduo",attrs:{"title":"查看更多"},on:{"click":function($event){return _vm.$router.push(`/platform/order/task`)}}})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }