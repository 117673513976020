<template>
  <div class="cpt-message-order-search">
    <div style="display: flex; align-items: center ">
      <el-form
        :model="search_form"
        style="margin-left: 24px "
        class="linear-form"
        inline
      >
        <el-form-item label="任务名称:" >
          <el-input
            v-model="search_form.name"
            size="mini"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="任务类型:">
          <el-select v-model="search_form.taskCateId" size="mini" placeholder="请选择任务类型" clearable>
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="需求单位:">
          <el-input
            v-model="search_form.organizationName"
            size="mini"
            placeholder="请输入需求单位"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="需求发起人:">
          <el-input
            v-model="search_form.userName"
            size="mini"
            placeholder="请输入需求发起人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEasy: false,
      search_form: {
        name: null,
        taskCateId: null,
        organizationName: null,
        userName: null,
        status: null,
      },
      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        status: [
          { id: 0, title: "待执行" },
          { id: 1, title: "已完成" },
          { id: 3, title: "执行中" },
        ],
        apply: [
          { id: 0, title: "待审批" },
          { id: 1, title: "审批通过" },
          { id: 2, title: "审批不通过" },
        ],
        processing: [
          { id: 0, title: "待处理" },
          { id: 1, title: "处理完成" },
          { id: 2, title: "无需处理" },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.cpt-message-order-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
::v-deep .el-input{
  width: 178px;
  .el-input__inner{
    width: 100% !important;
  }
}
</style>