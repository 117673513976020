<template>
  <Box>
    <div class="page-message-order-list">
      <Search  @on-search="handle_search" />
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #action="{ row }">
          <div class="action">
            <span v-if="row.processingStatus === 0" title="处理需求" @click="$router.push(`/platform/order/handle/${row.id}`)"  class="iconfont icon-chulixuqiu1"></span>
            <span title="查看更多" @click="$router.push(`/platform/order/task`)" class="iconfont icon-chakangengduo"></span>
          </div>
        </template>
      </Table>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import API from "@/api";
export default {
  components: { Box, Search, Table },
  data() {
    return {
      columns: [
        { prop: "taskTitle", label: "任务名称" },
        { prop: "taskCateName", label: "任务类型" },
        { prop: "ai", label: "配置AI", width: "100px" },
        { prop: "startedAt", label: "预计开始时间" },
        { prop: "phone", label: "联系电话" },
        
        { prop: "remark", label: "任务描述" },
        { prop: "organizationName", label: "需求单位" },
        { prop: "userName", label: "需求发起人" },
        { prop: "origin", label: "起始位置" },
        { prop: "processingStatusName", label: "处理状态" },
        { prop: "action", slot: true, label: "操作" },  
      ],
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      map: {
        status: {
          0: "待执行",
          1: "已完成",
          2: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
    };
  },
  methods: {
    async async_get_list() {
      const { map } = this;
      const { page, pageSize } = this.pagination;
      const { data } = await API.TASK.GetNeedsMessageList({
        ...this.search,
        pageNum: page,
        pageSize: pageSize,
      });
      this.dataSource = data.data || [];
      this.dataSource = this.dataSource.map((item) => ({
        ...item,
        taskStatusName: map.status[item.taskStatus],
        applyStatusName: map.apply[item.applyStatus],
        processingStatusName: map.processing[item.processingStatus],
      }));
      this.pagination.page = data.pageNo;
      this.pagination.total = data.totalCount;
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    }
  },
  mounted() {
    this.async_get_list();
  },
};
</script>

<style lang="scss" scoped>
.page-message-order-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 24px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }

    }
  }
}
</style>