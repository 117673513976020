<template>
  <div class="page-message-order">
    <List />
  </div>
</template>

<script>
import List from "./list";
export default {
  components: { List },
};
</script>

<style lang="scss" scoped>
.page-message-order {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
}
</style>